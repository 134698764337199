export const mediaSizes = {
  desktop: 992,
  tablet: 640,
  mobile: 320,
};

const { protocol } = window.location;

export const adminEmail = 'cityandinfo@gmail.com';
export const intelzEmail = 'JOA@intelz.kr';
export const dailyIssueEmail = 'dailyissue@gmail.com';
export const bannerEmails = [
  'gkiyun+ko@gmail.com',
  'gkiyun+zh@gmail.com',
  'gkiyun+en@gmail.com',
  'gkiyun+fr@gmail.com',
  'gkiyun+de@gmail.com',
  'gkiyun+id@gmail.com',
  'gkiyun+it@gmail.com',
  'gkiyun+ko@gmail.com',
  'gkiyun+ja@gmail.com',
  'gkiyun+ru@gmail.com',
  'gkiyun+es@gmail.com',
  'gkiyun+th@gmail.com',
  'gkiyun+vi@gmail.com',
  'gkiyun+ar@gmail.com',
];

export const dailyIssueId = '3cc9b57d-0d77-453e-8115-5c055184adbf';
export const impUserCode = 'imp50242004';

export const aiId = '3cecc983-d107-41a1-ad90-b79a803cf459';
export let isProductionServer;
export const BASE_URL_PRODUCTION = 'api.youbook.biz';
export const BASE_URL_STAGING = 'dev-api.youbook.biz';
export let API_HOST = '';
export let importPG;
export const USER_CLASS_PRODUCER = '프로듀서';
export const USER_CLASS_READER = '지식 탐구 독자';
export const USER_CLASS_ADMIN = '관리자';
export let eximbayMID = '';
export let paypalMID = '';

switch (process.env.REACT_APP_DEPLOYMENT_ENV) {
  case 'production': // Production Server
    isProductionServer = true;
    API_HOST = `${protocol}//${BASE_URL_PRODUCTION}`;
    importPG = 'html5_inicis.MOI6717024';
    eximbayMID = 'eximbay.28E027B98F';
    paypalMID = 'paypal_v2.J6ZEUHS3BL376';
    break;
  case 'development': // Staging Server
    isProductionServer = false;
    API_HOST = `${protocol}//${BASE_URL_STAGING}`;
    importPG = 'html5_inicis.INIpayTest';
    eximbayMID = 'eximbay.1849705C64';
    paypalMID = 'paypal_v2';
    break;
  default: // Local
    isProductionServer = false;
    API_HOST = 'https://dev-api.youbook.biz';
    // API_HOST = 'http://localhost:6001';
    importPG = 'html5_inicis.INIpayTest';
    eximbayMID = 'eximbay.1849705C64';
    paypalMID = 'paypal_v2';
    break;
}
