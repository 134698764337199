import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { pender } from 'redux-pender';
import { message } from 'antd';
import * as NeuronAPI from '../../network/api/publicApi';
import { getNeighborId } from '../../lib/Neo4jD3Old';
import translations from '../../utils/translations';
import storage from '../../lib/storage';

export const storeName = 'searchResult';

// 아래 컨텍스트 이름대로, initialState 에 추가해야함.
export const CONTEXT_HOME = 'home';
export const CONTEXT_SEARCH_RESULT = 'search_result';

// Initial State
// FIXME 아래 객체에 정보를 더 넣는 방법을 생각해보자. 하드코딩된 로직이 많지 않도록.
const initialState = {
  home: {
    startCellIds: [],
    listCells: [],
    graphCells: [],
    graphRelationships: [],
  },
  search_result: {
    selectedTab: 'list',
    startCellIds: [],
    listCells: [],
    graphCells: [],
    graphRelationships: [],
  },
};

// Action Types
const CHANGE_TAB = `${storeName}/CHANGE_TAB`;
const INIT_SEARCH_RESULT = `${storeName}/INIT_SEARCH_RESULT`;
const INIT_HOME_GRAPH = `${storeName}/INIT_HOME_GRAPH`;
const LOAD_AND_SHOW_INITIAL_CELLS = `${storeName}/LOAD_AND_SHOW_INITIAL_CELLS`;
const SHOW_INITIAL_CELLS = `${storeName}/SHOW_INITIAL_CELLS`;
const LOAD_AND_APPEND_GRAPH_CELLS = `${storeName}/LOAD_AND_APPEND_GRAPH_CELLS`;
const TOGGLE_GRAPH_CELL = `${storeName}/TOGGLE_GRAPH_CELL`;
const SELECT_GRAPH_CELL = `${storeName}/SELECT_GRAPH_CELL`;
const TOGGLE_ALL_GRAPH_CELLS = `${storeName}/TOGGLE_ALL_GRAPH_CELLS`;
const REMOVE_UNSELECTED_GRAPH_CELLS = `${storeName}/REMOVE_UNSELECTED_GRAPH_CELLS`;

const MAX_CELL_COUNT = 150;

// Action Creator
export const changeTab = createAction(CHANGE_TAB);
export const loadAndShowInitialCells = createAction(LOAD_AND_SHOW_INITIAL_CELLS, NeuronAPI.showCells);
export const showInitialCells = createAction(SHOW_INITIAL_CELLS);
export const loadAndAppendGraphCells = createAction(LOAD_AND_APPEND_GRAPH_CELLS, NeuronAPI.appendCells, (...meta) => meta);
export const toggleGraphCell = createAction(TOGGLE_GRAPH_CELL);
export const selectGraphCell = createAction(SELECT_GRAPH_CELL);
export const toggleAllGraphCells = createAction(TOGGLE_ALL_GRAPH_CELLS);
export const removeUnselectedGraphCells = createAction(REMOVE_UNSELECTED_GRAPH_CELLS);

export const initHomeGraph = createAction(INIT_HOME_GRAPH);
export const initSearchResult = createAction(INIT_SEARCH_RESULT);
export const initAndShowNeuronPage = (dispatch, history, startCellIds) => {
  dispatch(initSearchResult({ context: CONTEXT_SEARCH_RESULT, startCellIds }));
  history.push('/search/result');
};
export const initCustomFinder = (dispatch, startCellIds) => {
  dispatch(initSearchResult({ context: CONTEXT_SEARCH_RESULT, startCellIds }));
};

export const currentContext = () => {
  const { pathname } = window.location;

  if (pathname === '/home/cell' || pathname === '/') {
    return CONTEXT_HOME;
  } if (pathname === '/search/result' || pathname === '/home/collection') {
    return CONTEXT_SEARCH_RESULT;
  }
  return '';
};

// Reducers
export default handleActions({
  [CHANGE_TAB]: (state, action) => produce(state, (draft) => {
    const context = currentContext();
    draft[context].selectedTab = action.payload;
  }),
  [INIT_SEARCH_RESULT]: (state, action) => produce(state, (draft) => {
    const { context, startCellIds } = action.payload;

    draft[context].selectedTab = 'list';
    draft[context].startCellIds = startCellIds;
    draft[context].collectionId = '';
    draft[context].listCells = [];
    draft[context].graphCells = [];
    draft[context].graphRelationships = [];
  }),
  [INIT_HOME_GRAPH]: (state, action) => produce(state, (draft) => {
    const context = CONTEXT_HOME;

    draft[context].startCellIds = [];
    draft[context].listCells = [];
    draft[context].graphCells = [];
    draft[context].refreshCount = 0;
    draft[context].graphRelationships = [];
  }),
  ...pender({
    type: LOAD_AND_SHOW_INITIAL_CELLS,
    onSuccess: (state, action) => produce(state, (draft) => {
      const context = currentContext();

      const response = action.payload.data;

      draft[context].graphCells = [];
      draft[context].listCells = [];
      for (const cell of response.cells) {
        if (!draft[context].graphCells.some((item) => item.id === cell.id)) {
          cell.isSelected = false;
          cell.loadedAt = Date.now();

          draft[context].graphCells.push(cell);
          draft[context].listCells.push(cell);
        }
      }

      draft[context].graphRelationships = [];
      for (const relationship of response.relationships) {
        if (!draft[context].graphRelationships.some((item) => (item.start === relationship.start) && (item.end === relationship.end))) {
          relationship.isHighlighted = true;
          draft[context].graphRelationships.push(relationship);
        }
      }
    }),
  }),
  [SHOW_INITIAL_CELLS]: (state, action) => produce(state, (draft) => {
    const context = currentContext();
    if (context) {
      const { cells, relationships } = action.payload;

      draft[context].graphCells = [];
      draft[context].listCells = [];
      for (const cell of cells) {
        if (!draft[context].graphCells.some((item) => item.id === cell.id)) {
          cell.isSelected = false;
          cell.loadedAt = Date.now();

          draft[context].graphCells.push(cell);
          draft[context].listCells.push(cell);
        }
      }

      draft[context].graphRelationships = [];
      for (const relationship of relationships) {
        if (!draft[context].graphRelationships.some((item) => (item.start === relationship.start) && (item.end === relationship.end))) {
          relationship.isHighlighted = true;
          draft[context].graphRelationships.push(relationship);
        }
      }
    }
  }),
  ...pender({
    type: LOAD_AND_APPEND_GRAPH_CELLS,
    onSuccess: (state, action) => produce(state, (draft) => {
      const context = currentContext();
      const userLanguage = storage.get('userLanguage') || navigator.language.split('-')[0];
      const response = action.payload.data;

      const cellIds = action.meta[0];
      const isFromDoubleClick = action.meta[1];

      let cellsNew = draft[context].graphCells;
      // 새로 추가된 cell 가 있는지 판단하기 위한 flag.
      let addedCellCount = 0;
      // 중복되는 노드 제외하고 나머지 넣어줌.
      for (const cell of response.cells) {
        if (!cellsNew.some((item) => item.id === cell.id)) {
          addedCellCount += 1;
          cell.loadedAt = Date.now();
          cellsNew.push(cell);
        }

        // 새롭게 추가되는 셀은 20개까지만 넣어줌.
        if (addedCellCount >= 20) {
          console.log('20개 넘어서 짜름.');
          break;
        }
      }

      // relationship 이 새로 추가된다면, 이전의 highlight 는 다 꺼져야 하기 때문에, isHighlighted 를 모두 false 로 만든 배열을 만들어 둠.
      // 추가된 relationship 이 없는 경우에는, 직전 highlight 는 켜져있어야 하기 때문에, draft 에 직접 작업하지는 않는다.
      let relationshipNew = state[context].graphRelationships.map((item) => ({
        ...item,
        isHighlighted: false,
      }));

      // 새로 추가된 relationship 이 있는지 판단하기 위한 flag.
      let addedRelationshipCount = 0;
      // 위의 20개 커트라인에 의해 날아간 node 와 연결된 relationship 들도 날려버림.
      const filteredRelationships = response.relationships.filter((r) => cellsNew.some((cell) => cell.id === r.start) && cellsNew.some((cell) => cell.id === r.end));
      for (const relationship of filteredRelationships) {
        if (!relationshipNew.some((item) => (item.start === relationship.start) && (item.end === relationship.end))) {
          addedRelationshipCount += 1;
          relationship.isHighlighted = true;
          relationshipNew.push(relationship);
        }
      }

      if (addedCellCount === 0 && addedRelationshipCount === 0) {
        if (isFromDoubleClick) { // 더블 클릭을 통해 넘어온 경우는 더이상 추가할 정보가 없을 경우, 1차 연결 셀들을 삭제한다.
          // 더블클릭 된 노드.
          const clickedCell = cellIds[0];

          // 더블클릭된 노드와 1차 연결된 노드들의 ID. => 삭제대상.
          const neighborCellIds = relationshipNew.map((r) => getNeighborId(r, clickedCell)).filter((r) => !!r);

          if (neighborCellIds) {
            // 노드 리스트에서 삭제 대상 노드들을 지움.
            cellsNew = cellsNew.filter((n) => {
              const isNeighbor = neighborCellIds.includes(n.id);
              return !isNeighbor || (isNeighbor && n.isSelected);
            });
            // 릴레이션쉽 리스트에서 남아있는 노드의 릴레이션쉽만 남김.
            relationshipNew = relationshipNew.filter((r) => cellsNew.some((cell) => cell.id === r.start) && cellsNew.some((cell) => cell.id === r.end));
          } else {
            // 1차 연결 노드들이 없는 경우는 아무 반응이 없으면 이상하니까 메시지 보여줌.
            message.warn(translations[userLanguage].home_page_explor_alert3);
          }
        } else {
          message.warn(translations[userLanguage].home_page_explor_alert3);
        }
      }

      if (cellsNew.length > MAX_CELL_COUNT) {
        message.warn(translations[userLanguage].home_page_explor_alert2);
        // 선택된 노드들은 남기고, 선택안된 노드들 중에서 최신에 불러온 노드들만 남기기 위해 정렬한 후 잘라냄.
        cellsNew = cellsNew.sort((n1, n2) => {
          if (n1.isSelected && !n2.isSelected) {
            return 1;
          }
          if (!n1.isSelected && n2.isSelected) {
            return -1;
          }
          return n1.loadedAt - n2.loadedAt;
        }).slice(cellsNew.length - MAX_CELL_COUNT);

        relationshipNew = relationshipNew.filter((r) => (cellsNew.some((cell) => cell.id === r.start) && cellsNew.some((cell) => cell.id === r.end)));
      }

      draft[context].graphCells = cellsNew;
      draft[context].graphRelationships = relationshipNew;
    }),
  }),
  [TOGGLE_GRAPH_CELL]: (state, action) => {
    const context = currentContext();

    const targetCellId = action.payload;
    return produce(state, (draft) => {
      const targetCell = draft[context].graphCells.find((cell) => cell.id === targetCellId);
      if (targetCell) {
        targetCell.isSelected = !targetCell.isSelected;
      }
    });
  },
  [SELECT_GRAPH_CELL]: (state, action) => {
    const context = currentContext();

    const targetCellId = action.payload;
    return produce(state, (draft) => {
      const targetCell = draft[context].graphCells.find((cell) => cell.id === targetCellId);
      if (targetCell) {
        targetCell.isSelected = true;
      }
    });
  },
  [TOGGLE_ALL_GRAPH_CELLS]: (state, action) => produce(state, (draft) => {
    const context = currentContext();

    draft[context].graphCells = state[context].graphCells.map((cell) => ({
      ...cell,
      isSelected: !cell.isSelected,
    }));
  }),
  [REMOVE_UNSELECTED_GRAPH_CELLS]: (state, action) => produce(state, (draft) => {
    const context = currentContext();

    const newCells = state[context].graphCells.filter((cell) => cell.isSelected);
    const newRelationships = state[context].graphRelationships.filter((relationship) => (newCells.some((cell) => cell.id === relationship.start) && newCells.some((cell) => cell.id === relationship.end)));

    draft[context].graphCells = newCells;
    draft[context].graphRelationships = newRelationships;
  }),
}, initialState);
