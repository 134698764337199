import * as d3 from 'd3';

const width = 420;
const height = 540;
// 관계 선 색상
const lineColor = '#6d9eeb';
// 셀 크기
const circleRadius = 60;
// stroke width
const strokeWidth = 1.5;
// 일반(선택되지 않은) 셀 오버레이 색상
const normalOverlayColor = 'transparent';
// 선택된 셀 오버레이 색상.
const selectedOverlayColor = 'rgba(0,0,0,0.5)';

/**
 * distinct 한 두 배열이 같은지 판단하는 함수.
 * 두 배열 모두 distinct 하기 때문에 arrayA 의 모든 원소가 arrayB 에 존재한다면 같은 배열이라고 판단.
 */
function isSameArray(arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) {
    return false;
  }
  for (const a of arrayA) {
    if (!arrayB.some((b) => a === b)) {
      return false;
    }
  }
  return true;
}

export const getNeighborId = (relationship, circleId) => {
  if (relationship.start === circleId) {
    return relationship.end;
  }
  if (relationship.end === circleId) {
    return relationship.start;
  }
  return null;
};

const Neo4jD3Old = (selector, defaultScale, haveCenterForce, options) => {
  const scaledCircleRadius = circleRadius * defaultScale;
  const scaledStrokeWidth = strokeWidth * defaultScale;

  const getCircleRadius = (d) => (scaledCircleRadius);
  const getStrokeWidth = (d) => (scaledStrokeWidth);

  const elemSvgG = d3.select(selector)
    .append('svg')
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('class', 'neo4jd3-graph')
    .attr('viewBox', [-width / 2, -height / 2, width, height])
    .call(d3.zoom().on('zoom', (e) => {
      const scale = e.transform.k;
      const translate = [e.transform.x, e.transform.y];

      elemSvgG.attr('transform', `translate(${translate[0]}, ${translate[1]}) scale(${scale})`);
    }))
    .on('dblclick.zoom', null)
    .style('font', '12px sans-serif')
    .append('g')
    .attr('width', '100%')
    .attr('height', '100%');

  let link = elemSvgG.append('g')
    .attr('stroke', lineColor)
    // .attr('stroke-width', (d) => getStrokeWidth(d))
    .selectAll('line');

  const circleGroup = elemSvgG.append('g');
  // .attr('stroke-linecap', 'round')
  // .attr('stroke-linejoin', 'round');

  let circle = circleGroup.selectAll('g');

  function ticked() {
    link.attr('x1', (d) => d.source.x)
      .attr('y1', (d) => d.source.y)
      .attr('x2', (d) => d.target.x)
      .attr('y2', (d) => d.target.y);

    circle.attr('transform', (d) => `translate(${d.x},${d.y})`);
  }

  const simulation = d3.forceSimulation()
    .force('link', d3.forceLink().id((d) => d.id))
    .force('charge', d3.forceManyBody().strength(-400))
    .force('collide', d3.forceCollide().radius(1.5 * scaledCircleRadius))
    // .force('center', d3.forceCenter(width / 2, height / 2))
    .force('x', d3.forceX())
    .force('y', d3.forceY())
    .on('tick', ticked);
  if (haveCenterForce) simulation.force('center', d3.forceCenter(0, 0));

  const doubleClickDelay = 300;
  const onCircleClickWithCustomDebounce = (d) => {
    const current = Date.now();

    // 만약 이전에 클릭 된 기록이 있고, 그 기록이 딜레이 시간보다 작다면. 더블클릭으로 판단.
    if (d.clickTime && (current - d.clickTime) < doubleClickDelay) {
      // 이전 setTimeout 취소시킴.
      clearTimeout(d.clickHandle);
      // 더블클릭 이벤트로 새로운 setTimeout 설정.
      d.clickTime = current;
      d.clickHandle = setTimeout(() => {
        // 더블 클릭 이벤트 실행.
        options.onCircleDoubleClick(d);
        // 이벤트 수행 후에는 관련 변수 지워줌.
        d.clickTime = null;
        d.clickHandle = null;
      }, doubleClickDelay);
    } else { // 이전에 클릭한 기록이 없거나, 그 기록이 딜레이 시간보다 크다면, 일단 클릭을 시도.
      // 클릭 이벤트로 새로운 setTimeout 설정.
      d.clickTime = current;
      d.clickHandle = setTimeout(() => {
        // 클릭 이벤트 실행
        options.onCircleClick(d);
        // 이벤트 수행 후에는 관련 변수 지워줌.
        d.clickTime = null;
        d.clickHandle = null;
      }, doubleClickDelay);
    }
  };

  // long click 이벤트 발생 체크하는 타이머.
  let longClickChecker = null;
  // long click 이벤트가 발생했는지 여부.
  let longClickTriggered = false;
  function drag() {
    function dragStarted(event, d) {
      circle.data().forEach((n) => {
        n.fx = null;
        n.fy = null;
      });

      longClickTriggered = false;
      longClickChecker = setTimeout(() => {
        if (typeof options.onCircleLongClick === 'function') {
          longClickTriggered = true;

          // 현 이벤트에 대하여 드래그 리스너 삭제.
          event.on('drag', null);
          event.on('end', null);

          // 노드가 다시 자유롭게 움직일 수 있도록 처리.
          d.fx = null;
          d.fy = null;

          // 롱클릭 이벤트 실행.
          options.onCircleLongClick(d);
        }

        longClickChecker = null;
      }, 700);

      if (!event.active) simulation.alphaTarget(0.01).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      // 롱클릭 체크 시간이 되기 전에 마우스를 움직이기 시작했다면, 롱클릭 이벤트는 발생하지 않도록 처리.
      if (longClickChecker !== null) {
        clearTimeout(longClickChecker);
        longClickChecker = null;
      }
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragEnded(event, d) {
      // 롱클릭 체크 시간이 되기 전에 마우스를 움직이기 시작했다면, 롱클릭 이벤트는 발생하지 않도록 처리.
      if (longClickChecker !== null) {
        clearTimeout(longClickChecker);
        longClickChecker = null;
      }
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    return d3.drag()
      .on('start', dragStarted)
      .on('drag', dragged)
      .on('end', dragEnded);
  }

  return {
    updateData(data) {
      // 셀이 추가됐는지 판단하기 위해 업데이트 전의 셀 ID 들을 저장해 둠. 나중에 newDataIds 와 비교.
      // (예전 toggle 기능 때문에 필요했음. 셀의 데이터가 모두 같고, isSelected 만 다를 때, 그래프가 갱신되지 않는 문제가 있어서..)
      const oldDataIds = circle.data().map((d) => d.id);
      const old = new Map(circle.data().map((d) => [d.id, d]));
      const circles = data.cells.map((d) => Object.assign(old.get(d.id) || {}, d));
      const newDataIds = circles.map((d) => d.id);
      const relationships = data.relationships.map((d) => ({ ...d }));

      // 새로 추가된 노드에는 인접 노드들의 위치의 평균값을 초기값으로 넣어줌.
      for (const freshCircle of circles) {
        // x, y 값이 없다면 (= 새로 추가된 노드 라면)
        if (!freshCircle.x && !freshCircle.y) {
          if (freshCircle.isCenterFixed) { // 중간에 고정할 셀이라면,
            freshCircle.x = 0;
            freshCircle.y = 0;
            freshCircle.fx = 0;
            freshCircle.fy = 0;
          } else {
            const neighborCircleIds = relationships.map((r) => getNeighborId(r, freshCircle.id)).filter((neighbor) => neighbor > 0);
            const neighborCircles = circles.filter((n) => neighborCircleIds.includes(n.id));
            freshCircle.x = neighborCircles.reduce(((accumulator, currentValue) => accumulator + currentValue.x), 0) / neighborCircles.length;
            freshCircle.y = neighborCircles.reduce(((accumulator, currentValue) => accumulator + currentValue.y), 0) / neighborCircles.length;
          }
        }
      }

      link = link
        .data(relationships, (d) => [d.source, d.target])
        .join('line')
        .attr('stroke-width', (d) => getStrokeWidth(d))
        .attr('stroke-opacity', (d) => ((d.isHighlighted) ? 0.8 : 0.1));

      circle
        .data(circles, (d) => d.id)
        .join(
          (enter) => {
            const enterGroup = enter
              .append('g')
              .call(drag())
              .on('click', (event, d) => {
                if (options.onCircleLongClick && longClickTriggered) {
                  // 롱클릭 리스너가 있고, 이미 롱클릭 이벤트가 발생했다면 Click 이벤트는 스킵.
                } else if (options.onCircleDoubleClick) {
                  // 더블클릭 리스너가 있다면, 대기시간을 두고 더블클릭인지 원클릭인지 구분하여 동작할 필요가 있음.
                  onCircleClickWithCustomDebounce(d);
                } else if (typeof options.onCircleClick === 'function') {
                  // 더블클릭 리스너가 없고, 원클릭 리스너가 있다면 바로 실행.
                  options.onCircleClick(d);
                }
              });

            enterGroup
              .append('svg:image')
              .attr('class', 'slide-image')
              .attr('xlink:href', (d) => (d.imageURL))
              .attr('x', (d) => -1 * getCircleRadius(d))
              .attr('y', (d) => -1 * getCircleRadius(d))
              .attr('width', (d) => 2 * getCircleRadius(d))
              .attr('height', (d) => 2 * getCircleRadius(d));

            enterGroup
              .append('rect')
              .attr('class', 'overlay-color')
              .attr('x', (d) => -1 * getCircleRadius(d))
              .attr('y', (d) => -1 * getCircleRadius(d))
              .attr('width', (d) => 2 * getCircleRadius(d))
              .attr('height', (d) => 2 * getCircleRadius(d))
              .attr('fill', (d) => (d.isSelected ? selectedOverlayColor : normalOverlayColor));

            enterGroup
              .append('svg:image')
              .attr('class', 'overlay-image')
              .attr('xlink:href', (d) => (d.isSelected ? `${window.location.origin}/images/checkmark-256.png` : ''))
              .attr('x', (d) => -0.5 * getCircleRadius(d))
              .attr('y', (d) => -0.5 * getCircleRadius(d))
              .attr('width', (d) => getCircleRadius(d))
              .attr('height', (d) => getCircleRadius(d));
          },
          (update) => {
            update
              .select('.overlay-color')
              .attr('fill', (d) => (d.isSelected ? selectedOverlayColor : normalOverlayColor));

            update
              .select('.overlay-image')
              .attr('xlink:href', (d) => (d.isSelected ? `${window.location.origin}/images/checkmark-256.png` : ''));
          },
          (exit) => exit.remove(),
        );

      circle = circleGroup.selectAll('g');

      simulation.nodes(circles);
      simulation.force('link').links(relationships);

      if (isSameArray(oldDataIds, newDataIds)) {
        // update 전 과 후가 같은 배열이라면(선택 여부 정도만 바뀐 update 라면), tick(다시 그리기)만 한번 해줌.
        ticked();
      } else if (simulation.alpha() < 0.05) { // update 전과 후가 다른 배열이고, 움직임이 멈춘 상태라면, 정렬 재 시작.
        simulation.alpha(0.05).restart().tick();
      }
    },
  };
};

export default Neo4jD3Old;
